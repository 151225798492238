import React, { createContext, useContext, useEffect, useState } from "react";
import { PhoneXMarkIcon } from "@heroicons/react/24/solid";

import {
  patchAsyncTaskV2,
  patchAsyncTaskV2Tenant,
  patchMessageTaskMessages,
} from "api/asyncTasks";
import { addUserToConversation } from "api/conversations";
import {
  AsyncTask,
  AsyncTaskAssignmentType,
  AsyncTaskResourceType,
  CallScriptsPrefill,
  Conversation,
  MessageTask,
  MessageTaskMessageSeenListPayload,
  Tenant,
} from "api/types";
import { useDispatch, useSelector } from "hooks/redux";
import { InfoNodeType, InfoCardProps } from "componentsV2/InfoCard";
import { useSnackbar } from "modulesV2/Snackbar";
import {
  getConversations,
  handleOpenConversationException,
} from "services/conversations";
import { agentSelector } from "state/agentSlice";
import { deleteMessageTaskAlert } from "state/dashboardSlice";
import { tasksLocalStateSelector } from "state/tasksSlice";
import {
  getAsyncTaskCustomer,
  getAsyncTaskPhone,
  getCallScriptsPrefill,
} from "utils/asyncTask";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { formatShortDate } from "utils/date";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { mockAsyncTask } from "utils/mocks/tasks";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { displayName as facilityDisplayName } from "utils/facility";
import { useTasks } from "./TasksContext";
import {
  getVoicemailsArray,
  getInfoCardIcon,
  getInfoCardTitle,
} from "../utils";
import { getTenantPrimaryPhoneNumber } from "utils/tenant";

interface TaskCardContextValue {
  task: AsyncTask;
  facilityInfoModalOpen: boolean;
  followUpModalOpen: boolean;
  conversationModalOpen: boolean;
  isJoiningConversation: boolean;
  loading: boolean;
  isInProgress: boolean;
  isActive: boolean;
  isDialedInTask: boolean;
  startDisabled: boolean;
  setFacilityInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFollowUpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenConversation: () => Promise<void>;
  handleCloseConversation: () => void;
  handleSelectLead: (tenant: Tenant) => Promise<void>;
  handleUpdateFacility: (facilityId: number) => Promise<void>;
  handleHangUp: () => Promise<void>;
  handleStart: () => Promise<void>;
  handleDialIn: () => Promise<void>;
  handleFollowUp: (notes?: string) => Promise<boolean>;
  handleCompleteResolution: (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => Promise<void>;
  timeCreated: string;
  callScriptsPrefill: CallScriptsPrefill;
  infoCardProps: Pick<
    InfoCardProps,
    "title" | "info" | "Icon" | "iconClassName"
  >;
  voicemails: string[];

  conversations?: Conversation[];
}

const initialValue = {
  task: mockAsyncTask,
  facilityInfoModalOpen: false,
  conversationModalOpen: false,
  followUpModalOpen: false,
  isJoiningConversation: false,
  loading: false,
  isInProgress: false,
  isActive: false,
  isDialedInTask: false,
  startDisabled: false,
  handleSelectLead: asyncEmptyFn,
  handleUpdateFacility: asyncEmptyFn,
  setFacilityInfoModalOpen: emptyFn,
  setFollowUpModalOpen: emptyFn,
  handleOpenConversation: asyncEmptyFn,
  handleCloseConversation: emptyFn,
  handleHangUp: asyncEmptyFn,
  handleStart: asyncEmptyFn,
  handleDialIn: asyncEmptyFn,
  handleFollowUp: async () => true,
  handleCompleteResolution: asyncEmptyFn,
  timeCreated: "",
  callScriptsPrefill: {},
  infoCardProps: {
    title: "",
    info: [],
    Icon: PhoneXMarkIcon,
  },
  voicemails: [],
};

const TaskCardContext = createContext<TaskCardContextValue>(initialValue);

interface TaskCardProviderProps {
  children: React.ReactNode;
  task: AsyncTask;
}

export const TaskCardProvider: React.FC<TaskCardProviderProps> = ({
  children,
  task: initialTask,
}) => {
  const [task, setTask] = useState<AsyncTask>(initialTask);
  const [facilityInfoModalOpen, setFacilityInfoModalOpen] = useState(false);
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false);
  const [conversations, setConversations] = useState<
    Conversation[] | undefined
  >();
  const [conversationModalOpen, setConversationModalOpen] = useState(false);
  const [isJoiningConversation, setIsJoiningConversation] = useState(false);

  // Keep local task state in sync
  useEffect(() => {
    setTask(initialTask);
  }, [initialTask]);

  const customer = getAsyncTaskCustomer(task);

  useEffect(
    function clearConversation() {
      // Cached conversation should be cleared when tenant or facility are updated
      setConversations(undefined);
    },
    [customer?.id, task.facilityId]
  );

  const dispatch = useDispatch();
  const { dialedInAsyncTaskId, asyncTaskTimeDialedIn } = useSelector(
    tasksLocalStateSelector
  );
  const currentAgent = useSelector(agentSelector);
  const { showErrorSnack } = useSnackbar();

  const {
    tasksLoading,
    hangUpTask,
    handleStartTask,
    handleDialInTask,
    handleFollowUpTask,
    handleCompleteTask,
  } = useTasks();

  const loading = tasksLoading[task.id];
  const atas = task.asyncTaskAssignments;
  const latestAta = atas.length > 0 ? atas[atas.length - 1] : undefined;
  const isInProgress = latestAta?.status === AsyncTaskAssignmentType.InProgress;
  // Check latest task assignment for when another agent completes a task
  // and the ATAs are patched but isCompleted is still false
  const isCompleted =
    task.isCompleted || latestAta?.status === AsyncTaskAssignmentType.Completed;
  const isAssignee =
    atas.length > 0 && atas[atas.length - 1].assignee.id === currentAgent.id;
  const isActive = isInProgress && isAssignee;
  const isDialedInTask = task.id === dialedInAsyncTaskId;
  const startDisabled = isInProgress || isCompleted || loading;

  const handleSelectLead = async (tenant: Tenant) => {
    const res = await patchAsyncTaskV2Tenant(task, tenant);
    setTask(res.data);
  };

  const handleUpdateFacility = async (facilityId: number) => {
    // Safeguard since <FacilitySelect> would not render for these AsyncTaskResourceTypes
    switch (task.resourcetype) {
      case AsyncTaskResourceType.LeadFollowUpTask:
        throw new Error("Unable to update facility on lead follow up tasks");
      case AsyncTaskResourceType.MessageTask:
        throw new Error("Unable to update facility on message tasks");
      default:
    }

    const res = await patchAsyncTaskV2(task, { facilityId });
    setTask(res.data);
  };

  const handleHangUp = async () => hangUpTask(task);
  const handleStart = async () => handleStartTask(task);
  const handleDialIn = async () => handleDialInTask(task);
  const handleFollowUp = (notes?: string) => handleFollowUpTask(task, notes);
  const handleCompleteResolution = async (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => handleCompleteTask(task, isUsingOrgResolution, resolution);

  const timeCreated = formatShortDate(new Date(task.timeCreated));

  // For CallScripts
  const callScriptsPrefill = getCallScriptsPrefill(task);

  /**
   * InfoCard props
   */
  const infoArray: InfoNodeType[] = [];

  const facilityDisplayNameLabel = facilityDisplayName(task.facility);
  if (task.facility) {
    // Show clickable facility name
    infoArray.push({
      label: facilityDisplayNameLabel,
      onClick: () => {
        setFacilityInfoModalOpen(true);
      },
    });
  } else {
    const asyncTaskPhone = getAsyncTaskPhone(task);
    const asyncTaskDisplayPhone = formatE164ToDisplay(asyncTaskPhone);
    if (asyncTaskPhone && asyncTaskDisplayPhone) {
      // Show unknown facility with phone number called
      infoArray.push({
        label: `${facilityDisplayNameLabel} - ${asyncTaskDisplayPhone}`,
      });
    } else {
      // Show unknown facility without phone number
      infoArray.push({
        label: facilityDisplayNameLabel,
      });
    }
  }

  if (asyncTaskTimeDialedIn) {
    // Push call info with local start time (when task became dialed in)
    const callInfo: InfoNodeType = {
      label: "Active",
      startTime: asyncTaskTimeDialedIn,
    };
    infoArray.push(callInfo);
  }

  const infoCardProps = {
    title: getInfoCardTitle(task),
    info: infoArray,
    ...getInfoCardIcon(task),
  };

  // Audio
  const voicemails = getVoicemailsArray(task);

  // ConversationModal
  const patchConversationMessagesSeen = async (messageTask: MessageTask) => {
    const patchPayload = messageTask.messageTaskMessages.reduce<
      MessageTaskMessageSeenListPayload[]
    >((acc, { url, seen }) => (seen ? acc : [...acc, { url, seen: true }]), []);

    // Early exit if no messages to patch
    if (patchPayload.length === 0) {
      return;
    }

    try {
      const patchRes = await patchMessageTaskMessages(patchPayload);
      setTask({ ...messageTask, messageTaskMessages: patchRes.data });
      // Since patching messages doesn't trigger a "model_updated" event,
      // manually delete alert to dismiss associated snackbars.
      dispatch(deleteMessageTaskAlert(messageTask.id));
    } catch {
      showErrorSnack("Unable to mark messages as read");
    }
  };

  const getTaskConversations = async (): Promise<Conversation[]> => {
    // Conversation state already set.
    if (conversations) {
      return conversations;
    }

    if (task.resourcetype === AsyncTaskResourceType.MessageTask) {
      const fromPhoneNumber = task.conversation.customerPhoneNumber;
      const primaryPhoneNumber = task.customer
        ? getTenantPrimaryPhoneNumber(task.customer)
        : undefined;

      if (
        !task.customer ||
        !primaryPhoneNumber ||
        fromPhoneNumber !== primaryPhoneNumber
      )
        return [task.conversation];
    }

    const convos = await getConversations(customer);
    if (task.resourcetype === AsyncTaskResourceType.MessageTask) {
      convos.sort((a, b) => {
        if (a.id === task.conversation.id) return -1;
        if (b.id === task.conversation.id) return 1;
        return 0;
      });
    }
    return convos;
  };

  const handleOpenConversation = async () => {
    try {
      setIsJoiningConversation(true);
      const taskConversations = await getTaskConversations();
      setConversations(taskConversations);
      await Promise.all(
        taskConversations.map((callConvo) =>
          addUserToConversation({
            conversationId: callConvo.id,
            userId: currentAgent.id,
          })
        )
      );
      setConversationModalOpen(true);
      if (task.resourcetype === AsyncTaskResourceType.MessageTask) {
        patchConversationMessagesSeen(task);
      }
    } catch (e) {
      handleOpenConversationException(e, showErrorSnack);
    } finally {
      setIsJoiningConversation(false);
    }
  };

  const handleCloseConversation = () => {
    setConversationModalOpen(false);
  };

  return (
    <TaskCardContext.Provider
      value={{
        task,
        facilityInfoModalOpen,
        followUpModalOpen,
        conversationModalOpen,
        isJoiningConversation,
        loading,
        isInProgress,
        isDialedInTask,
        isActive,
        startDisabled,
        setFacilityInfoModalOpen,
        setFollowUpModalOpen,
        handleOpenConversation,
        handleCloseConversation,
        handleSelectLead,
        handleUpdateFacility,
        handleHangUp,
        handleStart,
        handleDialIn,
        handleFollowUp,
        handleCompleteResolution,
        timeCreated,
        callScriptsPrefill,
        infoCardProps,

        voicemails,
        conversations,
      }}
    >
      {children}
    </TaskCardContext.Provider>
  );
};

export const useTaskCard = () => useContext(TaskCardContext);

export const MockTaskCardProvider = createMockContextProvider(
  TaskCardContext,
  initialValue
);
