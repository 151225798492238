import { Facility, PmsType, SimpleFacility } from "api/types";

export const displayName = (facility?: SimpleFacility) => {
  if (facility) {
    return facility.altTitle ?? facility.title;
  }

  return "Unknown";
};

export const checkIfFacilityHasMorannonId = (facility?: Facility) =>
  typeof facility?.morannonId === "string" && facility.morannonId.length > 0;

export const checkIfFacilityHasMorannonPms = (facility?: Facility) =>
  facility?.pms === PmsType.Morannon;
