export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type Paginated<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export enum Status {
  Available = "available",
  Busy = "busy",
  Away = "away",
  Offline = "offline",
}

export enum TaskStatus {
  OnPhoneCall = "on_phone_call",
  OnOutboundPhoneCall = "on_outbound_phone_call",
  OnCounterCall = "on_counter_call",
}

export enum Role {
  Frontend = "frontend",
  Backend = "backend",
  PhoneOnly = "phone_only",
  CounterOnly = "counter_only",
}

export enum FeatureName {
  PmsIntegration = "PMS Integration",
  LeadFollowUp = "Lead Follow Up",
  CallCenter = "Call Center",
  Messages = "Messages",
  Zendesk = "Zendesk",
  AdvancedFacilitySettings = "Advanced Facility Settings",
}

export type Feature = {
  id: number;
  name: FeatureName;
};

export enum ResolutionCategory {
  NewLead = "NEW_LEAD",
  ExistingLead = "EXISTING_LEAD",
  Tenant = "TENANT",
  Vendor = "VENDOR",
  Other = "OTHER",
}

export type OrgResolution = {
  id: number;
  category: ResolutionCategory;
  value: string;
};

export type Organization = {
  id: number;
  name: string;
  storedgeCompanyId: string;
  pushChannelPrefix?: string;
  features: Feature[];
  resolutions: OrgResolution[];
};

export type Skill = {
  name: string;
};

export type SkillProficiency = {
  id: number;
  skill: Skill;
  skillId: number;
  url: string;
  weight: number;
};

export type User = {
  id: number;
  url: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  organization?: Organization;
  organizationId?: Organization["id"];
  phone?: string;
  imageUrl: string | null;
  isActive: boolean;
  isSecondaryAlertMuted: boolean;
  isBusy: boolean;
  availability: Status;
  ringtoneUrl?: string;
  role: Role;
  facilityUrls: string[];
  skillProficiencies: SkillProficiency[];
  taskChannelId: number;
  taskChannel?: {
    id: number;
    url: string;
    pusherChannelName: string;
    isPopulated: boolean;
  };
};

export type PDU = {
  id: number;
  url: string;
  ipAddress: string;
  // TODO: Not quite sure how records would be handled
  // when camelizing the keys. May need to have
  // custom middleware for this or just use snakecase.
  openLabels: { [key: string]: string };
  facilityId: number;
  isOpen?: boolean | null;
  errorText?: string;
};

export enum PmsType {
  None = "NONE",
  StorEdge = "STOREDGE",
  Morannon = "MORANNON",
}
export enum PhoneType {
  Mainline = "MAINLINE",
  Ads = "ADS",
  GoogleMyBusiness = "GOOGLE_MY_BUSINESS",
  Website = "WEBSITE",
  Original = "ORIGINAL",
  Collections = "COLLECTIONS",
  AfterHoursSupport = "AFTER_HOURS_SUPPORT",
}

export type FacilityPhone = {
  id: number;
  phoneNumber: string;
  type: PhoneType;
};

export type Facility = {
  id: number;
  url: string;
  title: string;
  altTitle?: string;
  facilityLocations: FacilityLocation[];
  facilityPhones: FacilityPhone[];
  // Why is PDU a list? Should the facilities tab
  // show PDUs instead of facilities? Can a facility
  // have more than one PDU?
  pdus: PDU[];
  isLive: boolean;
  isPreleasing: boolean;
  pms: PmsType;
  // Storeedge has 2 e's here, the backend
  // should probably change standardize to 1 or 2.
  storeedgeId?: string;
  morannonId?: string;
  city?: string;
  state?: string;
};

export type SimpleFacility = Pick<
  Facility,
  "id" | "url" | "title" | "altTitle"
>;

export type FacilityLocation = {
  id: number;
  title: string;
  facilityId: number;
  webexDevices: {
    title: string;
    sip: string;
  }[];
};

export enum ActivityType {
  LobbyDoorOpened = 1,
  FaceDetected = 2,
  CounterButtonPressed = 3,
}

export type FacilityActivity = {
  id: number;
  facilityId: number;
  facility: Facility;
  facilityLocationId: number;
  facilityLocation: FacilityLocation;
  type: ActivityType;
  description: string;
  timeOccurred: string;
};

export enum FacilityMetadataType {
  Notes = "NOTES",
  Contacts = "CONTACTS",
  Alerts = "ALERTS",
  Configs = "CONFIGS",
}

export enum FacilityMetadataDataType {
  String = "STRING",
  Number = "NUMBER",
  Array = "ARRAY",
  Json = "JSON",
  ExternalContent = "EXTERNAL_CONTENT",
}

export type FacilityMetadata = {
  id: number;
  url: string;
  facilityId: number;
  type: FacilityMetadataType;
  name: string;
  description: string;
  value: string;
  dataType: FacilityMetadataDataType;
  isEditable: boolean;
  isPublic?: boolean;
  contentType?: string;
  displayOrder: number;
  default?: boolean;
};

export type FacilityMetadataCreateType = {
  type: string;
  name: string;
  description?: string;
  value: string;
};

export enum RealtimeTaskType {
  CounterActivity = "COUNTER_ACTIVITY",
  ButtonPressed = "BUTTON_PRESSED",
  PhoneCall = "PHONE_CALL",
}

export enum RealtimeTaskStatus {
  Created = "CREATED",
  Delivered = "DELIVERED",
  Passed = "PASSED",
  Accepted = "ACCEPTED",
  Dismissed = "DISMISSED",
  Completed = "COMPLETED",
  Unknown = "UNKNOWN",
  Reassigned = "REASSIGNED",
}

export enum CustomerInteractionResolution {
  NotSet = "NOT_SET",
  ClosedSale = "CLOSED_SALE", // Deprecated
  MissedSale = "MISSED_SALE", // Deprecated
  GotCustomerInfo = "GOT_CUSTOMER_INFO",
  ProspectiveCustomer = "PROSPECTIVE_CUSTOMER",
  NoFit = "NO_FIT",
  SoldOut = "SOLD_OUT",
  Vendor = "VENDOR",
  ExistingTenant = "EXISTING_TENANT",
  Noke = "NOKE",
  Other = "OTHER",
  /* New secondary resolution */
  NL__NO_MORE_INTEREST = "NL__NO_MORE_INTEREST",
  NL__STILL_INTERESTED = "NL__STILL_INTERESTED",
  NL__MADE_RESERVATION = "NL__MADE_RESERVATION",
  NL__MOVED_IN = "NL__MOVED_IN",
  L__NO_MORE_INTEREST = "L__NO_MORE_INTEREST",
  L__STILL_INTERESTED = "L__STILL_INTERESTED",
  L__MADE_RESERVATION = "L__MADE_RESERVATION",
  L__MOVED_IN = "L__MOVED_IN",
  T__NOKE_APP_ASSISTANCE = "T__NOKE_APP_ASSISTANCE",
  T__NOKE_BATTERY = "T__NOKE_BATTERY",
  T__NOKE_HARDWARE = "T__NOKE_HARDWARE",
  T__NOKE_ACTIVATION_DELAY = "T__NOKE_ACTIVATION_DELAY",
  T__NOKE_PORTAL_SYNC_DELAY = "T__NOKE_PORTAL_SYNC_DELAY",
  T__NON_NOKE_ACCESS_ISSUE = "T__NON_NOKE_ACCESS_ISSUE",
  T__COLLECTIONS = "T__COLLECTIONS",
  T__FACILITY_MAINTAIN = "T__FACILITY_MAINTAIN",
  T__ADD_UNIT = "T__ADD_UNIT",
  T__GEN_ASSIST = "T__GEN_ASSIST",
  T__GEN_ASSIST_INSURANCE = "T__GEN_ASSIST_INSURANCE",
  T__GEN_ASSIST_ONLINE_PORTAL = "T__GEN_ASSIST_ONLINE_PORTAL",
  T__MOVE_OUT = "T__MOVE_OUT",
  V__NOKE = "V__NOKE",
  V__FACILITY_MAINTAIN = "V__FACILITY_MAINTAIN",
  V__THIRD_PARTY = "V__THIRD_PARTY",
  TWOMM_LEAD = "TWOMM_LEAD",
  NO_INTEREST = "NO_INTEREST",
  NOBODY_THERE = "NOBODY_THERE",
  NOBODY_THERE_BAD_WEATHER = "NOBODY_THERE_BAD_WEATHER",
  NOBODY_THERE_RESTROOMS = "NOBODY_THERE_RESTROOMS",
  GEN_ASSIST_INCIDENCE_SECURITY = "GEN_ASSIST_INCIDENCE_SECURITY",
  MISDIAL = "MISDIAL",
  AUCTION_BIDDER = "AUCTION_BIDDER",
  TENANT_PASSING_THROUGH = "TENANT_PASSING_THROUGH",
  TEAM_MEMBER = "TEAM_MEMBER",
  LOITERING = "LOITERING",
}

export enum CallStatus {
  Unknown = "UNKNOWN",
  Ended = "ENDED",
}

export type PhoneCall = {
  id: number;
  url?: string;
  twilioConferenceName?: string;
  twilioConferenceSid?: string;
  twilioCallSid?: string;
  timeCreated: string;
  status: CallStatus;
  fromPhoneNumber?: string;
  facilityPhoneNumber?: string;
  voicemailUrl?: string;
};

export enum SourceChannel {
  None = "NONE",
  WalkIn = "WALK_IN",
  Phone = "PHONE",
  Web = "WEB",
}

export enum MarketingSource {
  SawItDrivingBy = "SAW_IT_DRIVING_BY",
  OnlineMaps = "ONLINE_MAPS",
  GoogleAds = "GOOGLE_ADS",
  FriendsOrFamily = "FRIENDS_OR_FAMILY",
  FlyersOrBillboards = "FLYERS_OR_BILLBOARDS",
  SocialMedia = "SOCIAL_MEDIA",
  Others = "OTHERS",
}

export type Tenant = {
  id: number;
  facilityId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  pmsUrl?: string;
  pmsPaymentUrl?: string;
  pmsId?: string;
  source?: SourceChannel;
  marketingSource?: MarketingSource;
  phoneNumbers: {
    id: number;
    tenantId: number;
    phoneNumber?: string;
    isPrimary: boolean;
  }[];
  conversations?: Conversation[];
  isActive?: boolean;
  hasMovedIn?: boolean;
  isReserved?: boolean;
  timeCreatedInPms?: string;
};

// TODO: what's the difference between RealtimeTask.status
// and TaskAssignment.status? Should these be the same? Is
// TaskAssignment.status a legacy field? Are the values
// posible for TaskAssignment.status a subset of RealtimeTask.status?
export type RealtimeTask = {
  id: number;
  url: string;
  facility?: Facility;
  facilityId?: number;
  facilityLocationId: number;
  facilityLocation: FacilityLocation;
  type: RealtimeTaskType;
  status?: RealtimeTaskStatus; // Deprecated
  resolution: CustomerInteractionResolution;
  orgResolution?: string;
  additionalNotes: string;
  twilioConferenceName?: string;
  twilioCallSid?: string;
  phoneCall?: PhoneCall;
  phoneCallId?: number;
  tenant?: Tenant;
  tenantId?: number;
  timeCreated: string; // ISO 8601
  timeAccepted?: string;
  timeDismissed?: string;
  timeCompleted?: string;
  skillRequirements?: SkillRequirement[];
};

export type CallQueueItem = {
  url: string;
  facility?: SimpleFacility;
  timeCreated: string;
  type: RealtimeTaskType;
  phoneCall: Pick<PhoneCall, "fromPhoneNumber" | "facilityPhoneNumber">;
  skillRequirements: Pick<SkillRequirement, "skill" | "weight">[];
  tenant: Pick<Tenant, "firstName" | "lastName">;
};

export type CallQueue = {
  queue: CallQueueItem[];
  queueSequenceId: number;
  limit: number;
};

export type CreateTaskAssignmentBody = {
  status: string;
  assignee_url: string;
};

export type TaskAssignment = {
  id: number;
  url: string;
  realtimeTask: RealtimeTask;
  assignee: User;
  reassignedBy?: User;
  resassignedTo?: User;
  reassignedToUrl?: string;
  status: RealtimeTaskStatus; // make enum
  timeCreated: string; // ISO 8601
  timeAccepted?: string;
  timeDismissed?: string;
  timeEngaged?: string;
  timeCompleted?: string;
};

export enum AsyncTaskAssignmentType {
  InProgress = "IN_PROGRESS",
  Revisit = "REVISIT",
  Completed = "COMPLETED",
}

export type AsyncTaskAssignment = {
  id: number;
  url: string;
  asyncTaskId: number;
  assignee: User;
  assigneeId: number;
  status: AsyncTaskAssignmentType;
  notes: string;
  timeCreated: string;
  timeRevisit: string;
  timeCompleted: string;
};

export enum AsyncTaskResourceType {
  AsyncTask = "AsyncTask", // For backwards compatibility
  MissedCallFollowUpTask = "MissedCallFollowUpTask",
  LeadFollowUpTask = "LeadFollowUpTask",
  MessageTask = "MessageTask",
}

export type SkillRequirement = {
  id: number;
  taskId: number;
  skillId: number;
  skill: {
    name: string;
  };
  weight: number;
};

export type AsyncTaskV1 = {
  id: number;
  url: string;
  facility?: Facility;
  facilityId?: number;
  tenant?: Tenant;
  tenantId?: number;
  resolution: CustomerInteractionResolution;
  orgResolution?: string;
  additionalNotes: string;
  timeCreated: string;
  asyncTaskAssignments: AsyncTaskAssignment[];
  phoneCall?: PhoneCall;
  phoneCallId?: number;
  isCompleted: boolean;
  resourcetype: AsyncTaskResourceType.AsyncTask;
  skillRequirements: SkillRequirement[];
};

type AsyncTaskV2Base = Omit<AsyncTaskV1, "resourcetype">;

export interface MissedCallFollowUpTask extends AsyncTaskV2Base {
  resourcetype: AsyncTaskResourceType.MissedCallFollowUpTask;
  customerInteractionResult: CustomerInteractionResolution;
  caller: Tenant;
  callerId: number;
  missedCalls: PhoneCall[];
}

export interface LeadFollowUpTask extends AsyncTaskV2Base {
  resourcetype: AsyncTaskResourceType.LeadFollowUpTask;
  customerInteractionResult: CustomerInteractionResolution;
  leadTenant: Tenant;
  leadTenantId: number;
}

export interface MessageTask extends AsyncTaskV2Base {
  resourcetype: AsyncTaskResourceType.MessageTask;
  customerInteractionResult: CustomerInteractionResolution;
  customer: Tenant;
  customerId: number;
  conversation: Conversation;
  messageTaskMessages: MessageTaskMessage[];
}

export type AsyncTask =
  | AsyncTaskV1
  | MissedCallFollowUpTask
  | LeadFollowUpTask
  | MessageTask;

export type AsyncTaskFilters = {
  resourcetype: AsyncTaskResourceType;
  skillRequirements: string[];
  facilityIds: string[];
};

export interface Conversation {
  id: number;
  customerId?: number;
  customer?: Tenant;
  facilityId?: number;
  facility?: Facility;
  twilioConversationSid: string;
  customerPhoneNumber: string;
  facilityPhoneNumber: string;
}

export interface MessageTaskMessage {
  id: number;
  url: string;
  twilioMessageSid: string;
  time: string;
  source: string;
  seen: boolean;
  messageTaskId: number;
}

export type MessageTaskMessageSeenListPayload = Pick<
  MessageTaskMessage,
  "url" | "seen"
>;

export type PhoneToken = {
  identity: string;
  token: string;
};

export type PduOpenListPayload = {
  url: string;
  isOpen: boolean;
};

export type CallScriptsPrefill = {
  phoneNumber?: string;
};

export type DirectPhoneCall = {
  id: number;
  userId: number;
  toPhoneNumber: string;
  facilityPhoneNumber: string;
};

export type PhoneCallRecording = {
  presignedUrl: string;
};

export enum CommsEventType {
  INBOUND_PHONE_CALL = "INBOUND_PHONE_CALL",
  OUTBOUND_PHONE_CALL = "OUTBOUND_PHONE_CALL",
  COUNTER_CALL = "COUNTER_CALL",
  MISSED_CALL = "MISSED_CALL",
  VOICEMAIL = "VOICEMAIL",
  FOLLOW_UP_TEXT = "FOLLOW_UP_TEXT",
  FOLLOW_UP_CALL = "FOLLOW_UP_CALL",
  VERA_TEXT = "VERA_TEXT",
  VERA_CALL = "VERA_CALL",
  VERA_PAYMENT_ERROR = "VERA_PAYMENT_ERROR",
  VERA_PAYMENT_SUCCESS = "VERA_PAYMENT_SUCCESS",
}

export type CommsEvent = {
  id: number;
  type: CommsEventType;
  timeCreated: string;
  tenant: Tenant;
  content?: string;
};

export type PaymentToken = {
  token: string;
};

export type FacilityDocument = {
  id: number;
  active: boolean;
  name: string;
  availableAtMoveIn: boolean;
  description?: string;
  category?: string;
  fileName?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type FacilityUnit = {
  id: string;
  name: string;
};

export type FacilityPerson = {
  firstName: string;
  lastName: string;
  middleInitial?: string;
};

export type TenantLedger = {
  pmsLedgerId: string;
  chargeBalance?: number;
  overlocked?: boolean;
  paidThrough: string;
  totalBalance: number;
  unit: FacilityUnit;
  person: FacilityPerson;
};

export enum TenantDocumentStatus {
  CREATED = "CREATED",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED",
}

export type TenantDocument = {
  id: string;
  createdAt: string;
  updatedAt: string;
  documentId: string;
  pmsLedgerId?: string;
  pmsTenantId: string;
  formId?: string;
  formName?: string; // TODO: confirm this is returned for StorEdge facilities
  status: TenantDocumentStatus;
};

export type TenantLedgerCompletedDocument = {
  pmsLedgerId: string;
  documentIds: string[];
  formIds: string[];
};

export type TenantDocumentsResponse = {
  documentEnvelopes?: TenantDocument[];
  completedDocuments?: TenantLedgerCompletedDocument[];
};

export type TenantEsign = {
  url: string;
  pmsProperties?: Record<string, unknown>;
};
